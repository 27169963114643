import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DownloadIcon from '../../assets/images/icon-download-inversed.svg';
import UploadIcon from '../../assets/images/icon-upload-inversed.svg';

import {
  AsyncButton,
  CheckboxFilter,
  Filter,
  InventoryItem,
  Loader,
  NoResultsPlaceholder,
  PaginatedList,
  TextIconButton,
} from '../../components';
import { AddButton } from '../../components/forms';
import { IPaginatedListChangeEvent, IPaginatedListRef } from '../../components/PaginatedList/PaginatedList';
import {
  DEPARTMENT_IDS,
  FULFILLMENT_CENTER_ID,
  INVENTORY_FILTERS,
  INVENTORY_ITEM_LABEL,
  MAX_INVENTORY_ITEMS_PER_PAGE,
} from '../../constants/inventories';
import { routes, URL_VARS } from '../../constants/routing';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useDeptFilter from '../../hooks/useDeptFilter';
import useFulfillmentCenterFilter from '../../hooks/useFulfillmentCentersFilter';
import useScrollTop from '../../hooks/useScrollTop';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';
import useWindowSize from '../../hooks/useWindowSize';
import {
  addInventoryItemValue,
  downloadInventoryReportCSVRequest,
  fetchInventoryItemsRequest,
} from '../../store/actions/inventories';
import { toggleDownloadSuccessModal } from '../../store/actions/modals';
import { selectAdminType } from '../../store/selectors/auth';
import {
  selectCanEditInventory,
  selectInventoryItems,
  selectInventoryTotalCount,
  selectIsFinalInventoryItem,
  selectIsInventoryFetching,
  selectIsInventoryReportLoading,
  selectIsInventoryUploadCSVLoading,
} from '../../store/selectors/inventories';
import {
  IFetchInventoryItemsRequestMetadata,
  IFetchInventoryItemsRequestPayload,
  IInventoryItem,
  InventoryFilterTypesEnum,
} from '../../types/inventories';
import { IAddNewInventoryItemModalProps, IDeleteModalProps } from '../../types/modals';
import { IPromiseCallbacks } from '../../types/redux';
import { IApiError, IDownloadCSVSuccessPayload, NotificationListEnum } from '../../types/shell';
import { getMskuItemsMaxCount, getSortedInventoryItems, isItemMsku } from '../../utils/inventories';
import notification from '../../utils/notification';
import { hasPermission } from '../../utils/users';

import styles from './InventoryContainer.module.scss';

interface IProps {
  onDelete?: (props: IDeleteModalProps) => void;
  onAdd?: (props: IAddNewInventoryItemModalProps) => void;
  onUploadCSV?: () => void;
}

const InventoryContainer: React.FC<IProps> = ({ onDelete, onAdd, onUploadCSV }: IProps) => {
  const [filter, setFilter] = React.useState(InventoryFilterTypesEnum.All);
  const [preorderFilter, setPreorderFilter] = React.useState(false);

  const { SearchInput, query } = useSearchFilter([], [], {
    placeholder: 'Lookup items',
    hint: 'Look up specific items by their name or SKU.',
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const { mobile } = useWindowSize();
  const { setHeader, setAdditionalData } = useHeaderDispatch();

  const rootElement = React.useRef<HTMLDivElement>(null);
  const paginatedListRef = React.useRef<IPaginatedListRef<IInventoryItem>>(null);

  const adminType = useSelector(selectAdminType);
  const canEditInventory = useSelector(selectCanEditInventory);
  const inventoryItems = useSelector(selectInventoryItems(filter));
  const inventoryTotalCount = useSelector(selectInventoryTotalCount);
  const isFinalInventoryItem = useSelector(selectIsFinalInventoryItem);
  const IsInventoryFetching = useSelector(selectIsInventoryFetching);
  const isInventoryReportLoading = useSelector(selectIsInventoryReportLoading);
  const isInventoryUploading = useSelector(selectIsInventoryUploadCSVLoading);

  const sortedInventoryItems = React.useMemo(() => {
    return getSortedInventoryItems(inventoryItems);
  }, [inventoryItems]);

  const {
    DeptFilter,
    filteredItems: filteredItemsByDepartment,
    departments: selectedDepartment,
  } = useDeptFilter<IInventoryItem>(sortedInventoryItems || [], DEPARTMENT_IDS, {
    className: cn(styles.dropdownFilter, styles.divider),
    contentClassName: styles.dropdownContent,
    labelClassName: styles.dropdownLabel,
  });

  const {
    FulfillmentCenterFilter,
    filteredItems: filteredItemsByFC,
    fulfillmentCenters: selectedFulfillmentCenter,
  } = useFulfillmentCenterFilter<IInventoryItem>(filteredItemsByDepartment || [], FULFILLMENT_CENTER_ID, {
    className: styles.dropdownFilter,
    contentClassName: styles.dropdownContent,
    labelClassName: styles.dropdownLabel,
  });

  useScrollTop(filter, rootElement);

  const editItemOnClick = React.useCallback(
    (item: IInventoryItem) => {
      dispatch(addInventoryItemValue(item));
      history.push(routes.inventory.getInventoryUrl({ flowId: URL_VARS.EDIT, itemId: item.item_id }));
    },
    [dispatch, history],
  );

  const itemOnClick = React.useCallback(
    (item: IInventoryItem) => {
      if (canEditInventory) {
        editItemOnClick(item);
      } else {
        dispatch(addInventoryItemValue(item));
        history.push(routes.inventory.getInventoryUrl({ flowId: URL_VARS.VIEW, itemId: item.item_id }));
      }
    },
    [editItemOnClick, history, canEditInventory, dispatch],
  );

  const getRequestInfo = React.useCallback(
    (payload?: Partial<IFetchInventoryItemsRequestPayload>) => {
      return {
        type: filter,
        ...(preorderFilter && { include_preorder: 'on' }),
        page_size: MAX_INVENTORY_ITEMS_PER_PAGE,
        page: payload?.page || 1,
        ...(query && { search_query: query }),
        ...(selectedDepartment && { department_id: selectedDepartment as string }),
        ...(selectedFulfillmentCenter && { fulfillment_center_id: selectedFulfillmentCenter as string }),
        ...payload,
      };
    },
    [filter, selectedDepartment, selectedFulfillmentCenter, query, preorderFilter],
  );

  const fetchInventory = React.useCallback(
    (payload?: Partial<IFetchInventoryItemsRequestPayload>, metadata?: IFetchInventoryItemsRequestMetadata) =>
      dispatch(fetchInventoryItemsRequest(getRequestInfo(payload), metadata ?? { shouldClearItems: true })),
    [dispatch, getRequestInfo],
  );

  const handlePaginationChange = React.useCallback(
    ({ page, setPage }: IPaginatedListChangeEvent) => {
      fetchInventory({ page }, { shouldClearItems: false });
      // In case there's an error on the endpoint, paginator will change the page number, which is not correct.
      // The fix is to wrap the request with the Promise and call the `setPage(page)` after the Promise is resolved
      setPage(page);
    },
    [fetchInventory],
  );

  const deleteItemOnClick = React.useCallback(
    (id: string) => {
      if (onDelete) {
        onDelete({
          id,
          cb: () =>
            handlePaginationChange({
              page: paginatedListRef.current?.page || 1,
              setPage: paginatedListRef.current?.changePage!,
            }),
        });
      }
    },
    [handlePaginationChange, onDelete],
  );

  const downloadInventoryCSV = React.useCallback(
    (payload: IPromiseCallbacks) => {
      dispatch(downloadInventoryReportCSVRequest(payload));
    },
    [dispatch],
  );

  const handleDownloadClick = React.useCallback(() => {
    const downloadInventoryCSVPromise = new Promise<IDownloadCSVSuccessPayload>((resolve, reject) => {
      downloadInventoryCSV({ resolve, reject });
    }).then((response: IDownloadCSVSuccessPayload) => {
      dispatch(toggleDownloadSuccessModal(response));
    });

    notification.promise<void | IApiError>(NotificationListEnum.DownloadData, {
      promise: downloadInventoryCSVPromise,
      promiseParams: {
        pending: 'Downloading process started. We’ll notify you when the file is ready!',
        error: {
          render: ({ data: error }) =>
            (error && error.message) || "Something bad has happened. CSV file wasn't created",
        },
      },
    });
  }, [downloadInventoryCSV, dispatch]);

  const renderItems = React.useCallback(
    (items: IInventoryItem[]) =>
      items?.map((item) => {
        const {
          item_id,
          name,
          price,
          currency_code,
          count,
          image_url,
          sku_options,
          type,
          item_customization_allowed: isCustomizable,
          fulfillment_center_id,
          preorder_allowed,
        } = item;

        const isMsku = isItemMsku(item);

        const itemCount = isMsku ? getMskuItemsMaxCount(sku_options) : count;

        return (
          <div key={item_id} className={styles.item}>
            <InventoryItem
              fulfillment_center_id={fulfillment_center_id}
              itemId={item_id}
              type={type}
              title={name}
              price={price}
              currency_code={currency_code}
              icon={image_url}
              count={itemCount}
              isPreorder={preorder_allowed}
              skuOptions={sku_options}
              className={styles.wide}
              infoClassName={styles.itemInfo}
              iconClassName={styles.icon}
              isCustomizable={isCustomizable}
              onClick={() => itemOnClick(item)}
              {...(canEditInventory
                ? {
                    onDelete: () => deleteItemOnClick(item_id),
                    onEdit: () => editItemOnClick(item),
                  }
                : null)}
            />
          </div>
        );
      }),
    [canEditInventory, deleteItemOnClick, editItemOnClick, itemOnClick],
  );

  const renderControls = React.useMemo(() => {
    return (
      <div className={styles.controls}>
        {hasPermission([SUPER_ADMIN, DISTRIBUTOR, ORG_ADMIN], adminType) && (
          <React.Fragment>
            {mobile ? (
              <React.Fragment>
                {isInventoryUploading ? (
                  <AsyncButton
                    loaderClassName={styles.spinner}
                    disabled
                    outlined
                    className={styles.downloadBtn}
                    isLoading
                  />
                ) : (
                  <>
                    {hasPermission([SUPER_ADMIN], adminType) && (
                      <TextIconButton icon={UploadIcon} onClick={onUploadCSV} trigger />
                    )}
                  </>
                )}
                {isInventoryReportLoading ? (
                  <AsyncButton
                    loaderClassName={styles.spinner}
                    disabled
                    outlined
                    className={styles.downloadBtn}
                    isLoading
                  />
                ) : (
                  <TextIconButton icon={DownloadIcon} onClick={handleDownloadClick} trigger />
                )}
              </React.Fragment>
            ) : (
              <>
                {hasPermission([SUPER_ADMIN], adminType) && (
                  <AsyncButton
                    disabled={isInventoryUploading}
                    onClick={onUploadCSV}
                    className={styles.downloadBtn}
                    loaderClassName={styles.spinner}
                  >
                    Upload CSV
                  </AsyncButton>
                )}
                <AsyncButton
                  disabled={isInventoryReportLoading}
                  onClick={handleDownloadClick}
                  className={cn(styles.downloadBtn, styles.loaderBtn)}
                  loaderClassName={styles.spinner}
                  isLoading={isInventoryReportLoading}
                >
                  Download report
                </AsyncButton>
              </>
            )}
          </React.Fragment>
        )}
        {canEditInventory && (
          <AddButton assetName={INVENTORY_ITEM_LABEL.toLowerCase()} onClick={onAdd} className={styles.addButton} />
        )}
      </div>
    );
  }, [
    onUploadCSV,
    canEditInventory,
    onAdd,
    isInventoryUploading,
    isInventoryReportLoading,
    handleDownloadClick,
    adminType,
    mobile,
  ]);

  const filterConfig = React.useMemo(() => {
    return INVENTORY_FILTERS.map(({ filterId, label: optionLabel }) => {
      return {
        onClick: () => setFilter(filterId),
        isActive: filterId === filter,
        key: optionLabel,
        label: `${optionLabel}`,
      };
    });
  }, [filter]);

  React.useEffect(() => {
    if (!IsInventoryFetching) {
      fetchInventory();
    }

    paginatedListRef.current?.resetPageCount();
  }, [fetchInventory]);

  React.useEffect(() => {
    if (!setAdditionalData) {
      return;
    }

    setAdditionalData(mobile ? renderControls : null);

    // Need to clean additional information  after yourself
    return setAdditionalData;
  }, [mobile, setAdditionalData, renderControls]);

  React.useEffect(() => {
    if (typeof setHeader === 'function') {
      setHeader({ title: 'View Inventory', action: () => history.push(routes.dashboard) });
    }
  }, [history, setHeader]);

  return (
    <div className={styles.inventory} ref={rootElement}>
      <div className={styles.header}>
        <div className={styles.filters}>
          {SearchInput}
          <div className={styles.commonFilters}>
            <Filter className={styles.typeFilter} config={filterConfig} />
            <CheckboxFilter
              isActive={preorderFilter}
              label="Preorder"
              onClick={() => setPreorderFilter((prev) => !prev)}
              className={cn({
                [styles.divider]: hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN, DEPT_ADMIN], adminType),
              })}
            />
            {/* Dept Filter has to have an object */}
            {/* TODO: Change this in the context of EX-1244 task */}
            {hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType) ? DeptFilter({}) : null}
            {hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN, DEPT_ADMIN], adminType)
              ? FulfillmentCenterFilter({ isFiltered: true })
              : null}
          </div>
        </div>
        {!mobile && renderControls}
      </div>
      <div className={styles.content}>
        <Loader className={styles.loader} isLoading={IsInventoryFetching} />
        <PaginatedList<IInventoryItem>
          ref={paginatedListRef}
          items={filteredItemsByFC || ([] as IInventoryItem[])}
          pageSize={MAX_INVENTORY_ITEMS_PER_PAGE}
          totalCount={inventoryTotalCount}
          onChange={handlePaginationChange}
          isFinalItems={isFinalInventoryItem}
        >
          {({ items, pagination: Pagination }) => (
            <React.Fragment>
              {items && items.length ? (
                <div className={styles.list}>{renderItems(items)}</div>
              ) : (
                <NoResultsPlaceholder />
              )}
              <Pagination className={styles.pagination} />
            </React.Fragment>
          )}
        </PaginatedList>
      </div>
    </div>
  );
};

export default InventoryContainer;
