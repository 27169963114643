import cn from 'classnames';
import { FormikHelpers, useFormik } from 'formik';
import debounce from 'lodash/debounce';
import { useMemo } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SearchIcon } from '../../assets/images/icon-search.svg';
import {
  IntegrationWarningModal,
  LeadsAndContactsList,
  LookupSearch,
  OpportunitiesModal,
  OutOfMoneyToast,
} from '../../components';
import { ActionButton, DigitalShippingForm, ShippingForm } from '../../components/forms';
import { HUBSPOT } from '../../constants/integrations';
import { INTEGRATION_WARNINGS_INFO } from '../../constants/integrationWarnings';
import { SOB_OUT_OF_MONEY_MESSAGE } from '../../constants/shell';
import {
  AWAITING_ADDRESS,
  CONTACT,
  CRM_TYPE,
  IS_RECEIVER_ADDRESS_FIXED,
  RECEIVER_CRM_RECORD_ID,
  RECEIVER_CRM_RECORD_TYPE,
  SHIP_ORDER_STATUS,
} from '../../constants/shipping';
import { DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { useCurrency } from '../../contexts/CurrencyProvider';
import useModal from '../../hooks/useModal';
import { addShippingInformation, addShippingValue } from '../../store/actions/bucket';
import {
  chooseSearchItem,
  clearSearchItems,
  crmCheckStart,
  crmOAuthStart,
  getOpportunitiesRequest,
  searchLeadsAndContactsRequest,
} from '../../store/actions/integrations';
import { selectAdminType } from '../../store/selectors/auth';
import {
  selectCRMRecordId,
  selectEngagementCandidate,
  selectIsDigitalBucket,
  selectIsShippingOptionEnabled,
  selectIsSkipCrmEnabledForCurrentUserInOrg,
  selectRecipientValidationSchema,
  selectShippingInitialValues,
  selectSOBUserRemainingBudget,
} from '../../store/selectors/bucket';
import {
  selectCRMIsLoading,
  selectCrmSearchItems,
  selectIsCRMAuthenticated,
  selectIsQueryInProgress,
} from '../../store/selectors/integrations';
import {
  selectCurrentOrganizationOptions,
  selectIsCurrentOrganizationSupportedAnyCrm,
} from '../../store/selectors/organizations';
import { IFlowStepProps, TEngagementCandidate } from '../../types/bucket';
import { IOpportunityResponse, ISearchItem } from '../../types/crm';
import { IApiError, NotificationListEnum } from '../../types/shell';
import { IShippingFormValues } from '../../types/shipping';
import { transformSearchItemToShippingAddress } from '../../utils/crm';
import { getRequiredFields } from '../../utils/form';
import notification from '../../utils/notification';
import { hasPermission } from '../../utils/users';

import styles from './AddRecipientContainer.module.scss';

const AddRecipientContainer: React.FC<IFlowStepProps> = ({
  next,
  prev,
  isPYG,
  hasMsku,
  hasCustomizableItems,
  isDirectSend,
  ...flowProps
}) => {
  const [query, setQuery] = React.useState('');
  const controllerRef = React.useRef<AbortController | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { getSendTotalPrice } = useCurrency();

  const isSkipCrmEnabledForCurrentUser = useSelector(selectIsSkipCrmEnabledForCurrentUserInOrg);

  const engagementCandidate = useSelector(selectEngagementCandidate) as TEngagementCandidate;
  const items = useSelector(selectCrmSearchItems);
  const isCRMFetching = useSelector(selectCRMIsLoading);
  const isQueryInProgress = useSelector(selectIsQueryInProgress);
  const isDigital = useSelector(selectIsDigitalBucket);
  const isShippingOptionEnabled = useSelector(selectIsShippingOptionEnabled);
  const recordId = useSelector(selectCRMRecordId);
  const isCRMEnabled = useSelector(selectIsCurrentOrganizationSupportedAnyCrm);
  const isAuthenticatedToCRM = useSelector(selectIsCRMAuthenticated);
  const validationSchema = useSelector(selectRecipientValidationSchema);
  const initialValues = useSelector(selectShippingInitialValues);
  const adminType = useSelector(selectAdminType);
  const { crm_currently_supports: crmType } = useSelector(selectCurrentOrganizationOptions) || {};
  const SOBUserRemainingBudget = useSelector(selectSOBUserRemainingBudget);

  // Direct send and Skip CRM step behaviors are the same
  const isSkipCrmEnabledOnStep = React.useMemo(
    () => isDirectSend || isSkipCrmEnabledForCurrentUser,
    [isSkipCrmEnabledForCurrentUser, isDirectSend],
  );

  const shouldShowContactAdminMessage = useMemo(
    () => !hasPermission([SUPER_ADMIN, DISTRIBUTOR, ORG_ADMIN], adminType),
    [adminType],
  );

  const shouldForceDelayedShippingFlow = React.useMemo(
    () => isPYG || hasMsku || hasCustomizableItems,
    [isPYG, hasMsku, hasCustomizableItems],
  );

  const fieldConfig = React.useMemo(
    () => ({
      [SHIP_ORDER_STATUS]: {
        disabled: shouldForceDelayedShippingFlow,
      },
    }),
    [shouldForceDelayedShippingFlow],
  );

  const {
    openModal: openOpportunitiesModal,
    closeModal: closeOpportunitiesModal,
    Modal: ChooseOpportunitiesModal,
  } = useModal({ required: true });
  const {
    isOpen: isWarningModalOpen,
    openModal: openWarningModal,
    closeModal: closeWarningModal,
    Modal: WarningModal,
  } = useModal({ required: true });

  const crmCheck = React.useCallback(() => {
    if (isSkipCrmEnabledOnStep) {
      return;
    }
    dispatch(crmCheckStart());
  }, [dispatch, isSkipCrmEnabledOnStep]);

  const searchLeadsAndContacts = React.useCallback(
    debounce((name: string) => {
      if (controllerRef.current) {
        controllerRef.current.abort();
        controllerRef.current = null;
      }

      if (name && name.length >= 2) {
        controllerRef.current = new AbortController();
        return new Promise<ISearchItem[]>((resolve, reject) => {
          dispatch(
            searchLeadsAndContactsRequest({
              query: name,
              limit: 20,
              [CRM_TYPE]: crmType,
              signal: controllerRef.current?.signal,
              resolve,
              reject,
            }),
          );
        }).finally(() => {
          controllerRef.current = null;
        });
      }
    }, 1000),
    [dispatch, crmType],
  );

  const clearSearchResults = React.useCallback(() => {
    dispatch(clearSearchItems());
  }, [dispatch]);

  const disabledShippingForm = React.useMemo(
    () => Boolean(isCRMEnabled && !recordId && !isSkipCrmEnabledOnStep),
    [recordId, isCRMEnabled, isSkipCrmEnabledOnStep],
  );

  const chooseItem = React.useCallback(
    async (item: ISearchItem) => {
      const address = await transformSearchItemToShippingAddress(item, { isPYG: !!isPYG });
      dispatch(chooseSearchItem(address));

      return clearSearchResults();
    },
    [dispatch, clearSearchResults],
  );

  const addCrmIntegration = React.useCallback(() => {
    const crmIntegrationPromise = new Promise<void>((resolve, reject) => {
      dispatch(crmOAuthStart({ resolve, reject }));
    });

    notification.promise<void | IApiError>(NotificationListEnum.AddIntegrationPending, {
      promise: crmIntegrationPromise,
      promiseParams: {
        pending: 'Integration process started. We’ll notify you when it is ready!',
        success: 'The integration was added successfully!',
        error: 'Something went wrong. An error has occurred.',
      },
    });
  }, [dispatch]);

  const handleSubmit = React.useCallback(
    (
      values: Partial<IShippingFormValues>,
      { resetForm, setSubmitting }: FormikHelpers<Partial<IShippingFormValues>>,
    ) => {
      if (!validationSchema.isValidSync(values)) {
        return;
      }

      const successfulSubmit = () => {
        resetForm();
        setSubmitting(false);
        dispatch(addShippingInformation(values, flowProps));
        if (next) {
          history.push(next);
        }

        if (SOBUserRemainingBudget !== null && SOBUserRemainingBudget < getSendTotalPrice(engagementCandidate).price) {
          notification.outOfMoney(
            <OutOfMoneyToast showContactAdminMessage={shouldShowContactAdminMessage}>
              {SOB_OUT_OF_MONEY_MESSAGE}
            </OutOfMoneyToast>,
          );
        }
      };

      // Hubspot doesn't have the concept of Leads or Contacts,
      // so considering all the people from Hubspot as Contacts
      // and checking for the opportunities each time.
      if (
        isCRMEnabled &&
        values[RECEIVER_CRM_RECORD_ID] &&
        (values[RECEIVER_CRM_RECORD_TYPE] === CONTACT || crmType === HUBSPOT)
      ) {
        new Promise<IOpportunityResponse>((resolve, reject) => {
          dispatch(
            getOpportunitiesRequest({ cid: values[RECEIVER_CRM_RECORD_ID], [CRM_TYPE]: crmType }, { resolve, reject }),
          );
        }).then((response) => {
          const { totalSize } = response;

          if (totalSize > 0) {
            openOpportunitiesModal({ onChoose: successfulSubmit });
          } else {
            successfulSubmit();
          }
        });
      } else {
        successfulSubmit();
      }
    },
    [
      dispatch,
      isDigital,
      validationSchema,
      SOBUserRemainingBudget,
      engagementCandidate,
      shouldShowContactAdminMessage,
      openOpportunitiesModal,
      history,
      flowProps,
      next,
      crmType,
      getSendTotalPrice,
    ],
  );

  const form = useFormik<Partial<IShippingFormValues>>({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values, actions) => {
      const castedValues = validationSchema.cast(values);
      if (castedValues) {
        handleSubmit(castedValues, actions);
      }
    },
  });

  const handleFieldChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.target;

      if (!name) {
        console.warn('No field name was specified');
        return;
      }

      let value: string | boolean = e.target.value;

      if (name === IS_RECEIVER_ADDRESS_FIXED) {
        value = checked;
      }

      if (name === SHIP_ORDER_STATUS) {
        value = e.target.checked ? AWAITING_ADDRESS || e.target.checked : '';
      }

      form.setFieldValue(name, value);

      dispatch(addShippingValue({ [name]: value }));
    },
    [dispatch, form.setFieldValue],
  );

  // This effect is needed as a safety catch for PYG flow.
  // In some cases (when there's no CRM in the org) SHIP_ORDER_STATUS can be turned off,
  // which should not happen obviously
  React.useEffect(() => {
    if (shouldForceDelayedShippingFlow && form.values?.[SHIP_ORDER_STATUS] !== AWAITING_ADDRESS) {
      dispatch(addShippingValue({ [SHIP_ORDER_STATUS]: AWAITING_ADDRESS }));
    }
  }, [shouldForceDelayedShippingFlow, form.values?.[SHIP_ORDER_STATUS], dispatch]);

  React.useEffect(() => {
    if (
      !isWarningModalOpen &&
      isCRMEnabled &&
      isAuthenticatedToCRM !== undefined &&
      !isAuthenticatedToCRM &&
      !isSkipCrmEnabledOnStep
    ) {
      openWarningModal();
    }
  }, [openWarningModal, isWarningModalOpen, isCRMEnabled, isAuthenticatedToCRM, isSkipCrmEnabledOnStep]);

  React.useEffect(() => {
    if (isSkipCrmEnabledOnStep) {
      return;
    }
    crmCheck();
  }, [crmCheck, isSkipCrmEnabledOnStep]);

  React.useEffect(() => {
    searchLeadsAndContacts(query);

    return clearSearchResults;
  }, [query, searchLeadsAndContacts, clearSearchResults]);

  const handleWarningClose = React.useCallback(() => {
    if (!isSkipCrmEnabledOnStep && prev) {
      history.push(prev);
    }

    closeWarningModal();
  }, [closeWarningModal, history, prev, isSkipCrmEnabledOnStep]);

  const Form = React.useMemo(() => (isDigital ? DigitalShippingForm : ShippingForm), [isDigital]);

  const requiredFields = React.useMemo(() => getRequiredFields(validationSchema), [validationSchema]);

  const chooseOpportunitiesModal = React.useMemo(
    () => (
      <ChooseOpportunitiesModal>
        {(props: { onChoose: () => void }) => <OpportunitiesModal onClose={closeOpportunitiesModal} {...props} />}
      </ChooseOpportunitiesModal>
    ),
    [closeOpportunitiesModal, ChooseOpportunitiesModal],
  );

  const warningModal = React.useMemo(() => {
    const { icon, description } = crmType
      ? INTEGRATION_WARNINGS_INFO[crmType]
      : { icon: null as unknown as React.FC<any>, description: null };

    const action = (
      <ActionButton
        title="Continue"
        onClick={() => {
          addCrmIntegration();
          handleWarningClose();
        }}
      />
    );

    return (
      <WarningModal className="integration-warning">
        {() => (
          <IntegrationWarningModal onClose={handleWarningClose} icon={icon} description={description} action={action} />
        )}
      </WarningModal>
    );
  }, [history, handleWarningClose, WarningModal]);

  const handleLookupInputClick = React.useCallback(() => {
    if (isSkipCrmEnabledOnStep && !isAuthenticatedToCRM) {
      openWarningModal();
    }
  }, [isSkipCrmEnabledOnStep, isAuthenticatedToCRM]);

  return (
    <React.Fragment>
      {warningModal}
      {chooseOpportunitiesModal}
      {isDirectSend && (
        <div className={styles.directSendText}>
          When you create a Direct Send, you can quickly order your products without the steps of adding personalized
          postcards and emails
        </div>
      )}
      {isCRMEnabled ? (
        <LookupSearch
          onClick={handleLookupInputClick}
          containerClassName={styles.lookUpSearch}
          value={query}
          onFocus={(e, value) => setQuery(value)}
          onChange={(e, value) => setQuery(value)}
          placeholder="Lookup Contact"
          disabled={isCRMFetching || (isSkipCrmEnabledOnStep && !isAuthenticatedToCRM)}
          isLoading={isQueryInProgress}
          icon={<SearchIcon />}
          result={() => <LeadsAndContactsList onSelect={chooseItem} items={items} />}
        />
      ) : null}
      <Form
        className={styles.form}
        onChange={handleFieldChange}
        disabled={disabledShippingForm}
        hasMsku={hasMsku}
        hasCustomizableItems={hasCustomizableItems}
        isShippingOptionEnabled={isShippingOptionEnabled}
        isCRMEnabled={Boolean(isCRMEnabled)}
        form={form}
        config={fieldConfig}
        requiredFields={requiredFields}
        isDirectSend={isDirectSend}
      />
      <div className={cn(styles.controls)}>
        <ActionButton
          disabled={!form.isValid || disabledShippingForm}
          type="submit"
          className={styles.continueButton}
          title="Continue"
          onClick={form.handleSubmit}
        />
      </div>
    </React.Fragment>
  );
};

export default AddRecipientContainer;
