import { FormikValues } from 'formik';
import * as React from 'react';

import { countryList } from '../../constants/countries';
import { COUNTRY } from '../../constants/shipping';
import { ISelectorValue } from '../../types/shell';
import { Selector } from '../forms';

interface ICountrySelectorProps extends FormikValues {
  label?: string;
  className?: string;
  containerClassName?: string;
  error?: string | string[] | undefined;
  required?: boolean;
}

const CountrySelector: React.FC<ICountrySelectorProps> = ({
  label,
  className,
  containerClassName,
  required,
  error,
  value,
  onChange,
}) => {
  const countryOptions = React.useMemo(() => {
    return countryList.map((country) => {
      return {
        value: country.two_digit_code,
        label: country.name,
      };
    });
  }, []);

  const handleChange = React.useCallback(
    (selectedValue: ISelectorValue) => {
      if (onChange) {
        onChange(COUNTRY, selectedValue);
      }
    },
    [onChange],
  );

  const selectedCountry = React.useMemo(() => {
    return countryOptions.find((option) => option.value === value) || null;
  }, [value, countryOptions]);

  return (
    <Selector
      helperText={label}
      isClearable={true}
      isSearchable={true}
      className={className}
      containerClassName={containerClassName}
      value={selectedCountry}
      closeMenuOnSelect
      placeholder="Search a country"
      onChange={(v) => handleChange(v as ISelectorValue)}
      options={countryOptions || []}
      required={required}
      error={error}
    />
  );
};

export default CountrySelector;
