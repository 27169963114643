import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import variables from '../../../_variables.scss';
import sideNavStyles from '../Sidenav.module.scss';
import styles from './MobileSidenav.module.scss';

import { ReactComponent as AlertIcon } from '../../../assets/images/icon-alert.svg';
import { ReactComponent as BudgetSettingsIcon } from '../../../assets/images/icon-budget-settings.svg';
import { ReactComponent as DepartmentIcon } from '../../../assets/images/icon-building.svg';
import { ReactComponent as BurgerIcon } from '../../../assets/images/icon-burger.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import { ReactComponent as EnterIcon } from '../../../assets/images/icon-enter.svg';
import { ReactComponent as FeedbackIcon } from '../../../assets/images/icon-feedback.svg';
import { ReactComponent as SignatureIcon } from '../../../assets/images/icon-signature-with-a-pen.svg';

import { routes } from '../../../constants/routing';
import { DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN, USER } from '../../../constants/users';
import useFeatureBudgetErrors from '../../../hooks/useFeatureBudgetErrors';
import useFirebaseTools from '../../../hooks/useFirebaseTools';
import useFlowGuard from '../../../hooks/useFlowGuard';
import useModal from '../../../hooks/useModal';
import useNoScroll from '../../../hooks/useNoScroll';
import { toggleUiElement } from '../../../store/actions/shell';
import { selectAdminType, selectUserProfile } from '../../../store/selectors/auth';
import { selectIsDepartmentBudgetEnabled } from '../../../store/selectors/organizations';
import { selectIsShowDepartmentBudget, selectIsShowDigitalBudget } from '../../../store/selectors/shell';
import { BUTTON_BUTTON } from '../../../types/forms';
import { DrawerAnimationDirectionEnum } from '../../../types/shell';
import { hasPermission } from '../../../utils/users';
import Drawer from '../../Drawer/Drawer';
import {
  DepartmentBudget,
  DigitalBudget,
  DisplayCurrencyMenuItem,
  LowInventoryMenuItem,
  UserBudget,
  UserInformation,
} from '../../index';
import { ChangeOrganizationModal } from '../../modals';
import Nav from '../Nav/Nav';
import NavIcon from '../NavIcon/NavIcon';
import NavItem from '../NavItem/NavItem';
import NavText from '../NavText/NavText';

interface IProps {
  expanded: boolean;
  toggle: () => void;
  mainMenu: React.ReactNode | React.ReactNode[];
  additionalMenu: React.ReactNode | React.ReactNode[];
  isAuth: boolean;
}

const MobileSidenav = ({ expanded, toggle, mainMenu, additionalMenu, isAuth }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isSingle: isInSingleFlow } = useFlowGuard(false);
  const { logout } = useFirebaseTools();
  const {
    isOpen: isOpenChangeOrgModal,
    openModal: openChangeOrgModal,
    closeModal: closeChangeOrgModal,
    Modal: ChangeOrgModal,
  } = useModal();
  const { hasWarnings, setFeatureBudgetWarning } = useFeatureBudgetErrors();

  const auth = useSelector(selectUserProfile);
  const isShowDigitalBudget = useSelector(selectIsShowDigitalBudget);
  const isShowDepartmentBudget = useSelector(selectIsShowDepartmentBudget);
  const adminType = useSelector(selectAdminType);
  const isDepartmentBudgetEnabled = useSelector(selectIsDepartmentBudgetEnabled);

  const additionalInformation = React.useMemo(() => {
    return (
      <div className={styles.top}>
        <UserInformation
          wrapperClassName={styles.userInformation}
          avatarClassName={styles.avatar}
          name={auth?.displayName}
          avatarImageURL={auth?.photoURL}
        />
        {isInSingleFlow && <UserBudget className={styles.userBudget} />}
        <div className={styles.featureBudgetsList}>
          {isShowDigitalBudget && <DigitalBudget onLowBudget={setFeatureBudgetWarning} />}
          {isDepartmentBudgetEnabled && isShowDepartmentBudget && (
            <DepartmentBudget onLowBudget={setFeatureBudgetWarning} />
          )}
        </div>
        {!hasPermission([USER], adminType) && (
          <LowInventoryMenuItem className={styles.lowInventoryBtn} onClick={toggle} />
        )}
        <DisplayCurrencyMenuItem />
      </div>
    );
  }, [
    auth?.displayName,
    auth?.photoURL,
    isInSingleFlow,
    isShowDigitalBudget,
    setFeatureBudgetWarning,
    isDepartmentBudgetEnabled,
    isShowDepartmentBudget,
    adminType,
    toggle,
  ]);

  const changeOrgModal = React.useMemo(
    () => (
      <ChangeOrgModal className="common-modal">
        {() => {
          return <ChangeOrganizationModal onClose={closeChangeOrgModal} />;
        }}
      </ChangeOrgModal>
    ),
    [closeChangeOrgModal, ChangeOrgModal],
  );

  const handleLogout = React.useCallback(() => {
    // Need to close sidenav after logout
    if (toggle) {
      toggle();
    }
    logout();
  }, [toggle, logout]);

  const handleLogoOnClick = React.useCallback(() => history.push(routes.dashboard), [history]);

  const openDepartmentBudgetSidebar = React.useCallback(() => {
    dispatch(toggleUiElement({ drawerIsShown: true }));
  }, [dispatch]);

  const handleOpenFeedbackSidebar = React.useCallback(() => {
    dispatch(toggleUiElement({ feedbackSidenavIsShown: true }));
  }, [dispatch]);

  useNoScroll(expanded);

  return (
    <div className={cn(styles.mobileSideNav)}>
      <div className={cn(styles.header)}>
        <button type={BUTTON_BUTTON} onClick={handleLogoOnClick}>
          <div className={styles.sidebarLogo} />
        </button>
        {isAuth && (
          <div className={styles.controls}>
            <div className={styles.features}>
              {isDepartmentBudgetEnabled && hasPermission([SUPER_ADMIN, DISTRIBUTOR, ORG_ADMIN], adminType) && (
                <button type={BUTTON_BUTTON} onClick={openDepartmentBudgetSidebar}>
                  <BudgetSettingsIcon className={styles.icon} />
                </button>
              )}
              {hasPermission([SUPER_ADMIN, DISTRIBUTOR], adminType) ? (
                <React.Fragment>
                  <NavLink to={routes.signatureSetup} activeClassName={sideNavStyles.activated}>
                    <SignatureIcon className={styles.icon} />
                  </NavLink>
                  <button
                    type={BUTTON_BUTTON}
                    className={cn({ [sideNavStyles.activated]: isOpenChangeOrgModal })}
                    onClick={openChangeOrgModal}
                  >
                    <DepartmentIcon className={styles.icon} />
                  </button>
                </React.Fragment>
              ) : null}
            </div>
            <div className={styles.burgerWrapper}>
              <BurgerIcon onClick={toggle} />
              {hasWarnings && <AlertIcon className={styles.alert} />}
            </div>
          </div>
        )}
      </div>
      <Drawer
        className={cn(styles.body, { [styles.expanded]: expanded })}
        trigger={expanded}
        direction={DrawerAnimationDirectionEnum.Horizontally}
        from={variables.navMobileMinWidth}
        to={variables.navMobileMaxWidth}
        onClose={toggle}
        withOverlay
      >
        <CloseIcon className={styles.closeBtn} onClick={toggle} />
        {additionalInformation}
        <Nav>
          {mainMenu}
          <div className={styles.controls}>
            {additionalMenu}
            <NavItem onClick={handleOpenFeedbackSidebar} className={styles.logOut}>
              <NavIcon>
                <FeedbackIcon />
              </NavIcon>
              {expanded ? <NavText title="Leave Feedback" /> : null}
            </NavItem>
            <NavItem onClick={handleLogout} className={styles.logOut}>
              <NavIcon>
                <EnterIcon />
              </NavIcon>
              {expanded ? <NavText title="Log Out" /> : null}
            </NavItem>
          </div>
        </Nav>
      </Drawer>
      {changeOrgModal}
    </div>
  );
};

export default MobileSidenav;
