import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ContinueArrowIcon } from '../../assets/images/icon-next.svg';
import {
  BucketTotal,
  CheckboxFilter,
  FloatingButton,
  InventoryItem,
  Loader,
  NoResultsPlaceholder,
  PaginatedList,
  UnsavedChangesModal,
} from '../../components';
import { ActionButton } from '../../components/forms';
import { IPaginatedListChangeEvent, IPaginatedListRef } from '../../components/PaginatedList/PaginatedList';

import { CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID, PRE_CREATED_ENGAGEMENT_ID } from '../../constants/bucket';
import { FULFILLMENT_CENTER_ID, MAX_INVENTORY_ITEMS_PER_PAGE } from '../../constants/inventories';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { useCurrency } from '../../contexts/CurrencyProvider';

import useFulfillmentCenterFilter from '../../hooks/useFulfillmentCentersFilter';
import useModal from '../../hooks/useModal';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';

import {
  addBucketValue,
  addToBucket,
  changeBucketItemValue,
  removeFromBucket,
  resetCustomSend,
} from '../../store/actions/bucket';
import { fetchInventoryItemsRequest } from '../../store/actions/inventories';
import { toggleUiElement } from '../../store/actions/shell';
import { selectAdminType } from '../../store/selectors/auth';
import { selectBucketItems } from '../../store/selectors/bucket';
import {
  selectInventoryItems,
  selectInventoryTotalCount,
  selectIsFinalInventoryItem,
  selectIsInventoryFetching,
} from '../../store/selectors/inventories';
import { selectIsBoxDetailsSidebarShown } from '../../store/selectors/shell';

import { IChangeBucketItemPayload, IFlowStepProps } from '../../types/bucket';
import { ICampaignItem, ICustomSend } from '../../types/campaigns';
import {
  IFetchInventoryItemsRequestMetadata,
  IFetchInventoryItemsRequestPayload,
  IInventoryItem,
  InventoryFilterTypesEnum,
} from '../../types/inventories';
import { BoxDetailsSidebarModeEnum } from '../../types/shell';

import { isCampaignEligibleToOrder } from '../../utils/campaigns';
import { getSortedInventoryItems, isBrokenMSKU, isItemDigital, isItemOutOfStock } from '../../utils/inventories';
import { hasPermission } from '../../utils/users';

import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import { BoxDetailsSidebar } from '../index';

import styles from './CustomSendContainer.module.scss';

export interface ICustomBoxContainerProps extends IFlowStepProps {
  className?: string;
}

const CustomSendContainer: React.FC<ICustomBoxContainerProps> = ({ className, next, isDirectSend }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setAction: setHeaderAction } = useHeaderDispatch();
  const { getCampaignTotal } = useCurrency();

  const boxDetailsSidebarIsShown = useSelector(selectIsBoxDetailsSidebarShown);

  const { SearchInput, query } = useSearchFilter([], [], {
    className: styles.searchByFilterContainer,
    placeholder: 'Lookup items',
    hint: 'Look up specific items by their name or SKU.',
  });

  const inventoryItems = useSelector(selectInventoryItems());
  const inventoryCount = useSelector(selectInventoryTotalCount);
  const isFinalInventoryItem = useSelector(selectIsFinalInventoryItem);
  const IsInventoryFetching = useSelector(selectIsInventoryFetching);
  const bucketItems = useSelector(selectBucketItems);
  const adminType = useSelector(selectAdminType);
  const [preorderFilter, setPreorderFilter] = React.useState(false);

  const sortedInventoryItems = React.useMemo(() => {
    return getSortedInventoryItems(inventoryItems);
  }, [inventoryItems]);

  const {
    FulfillmentCenterFilter,
    filteredItems: filteredItemsByFC,
    fulfillmentCenters: selectedFulfillmentCenter,
  } = useFulfillmentCenterFilter<IInventoryItem>(sortedInventoryItems || [], FULFILLMENT_CENTER_ID, {
    className: styles.dropdownFilter,
    contentClassName: styles.dropdownContent,
    labelClassName: styles.dropdownLabel,
  });

  const paginatedListRef = React.useRef<IPaginatedListRef<IInventoryItem>>(null);

  const bucketItemsDisplayedCount = React.useMemo(() => bucketItems?.length || 0, [bucketItems]);
  const allowedFulfillmentCenter = React.useMemo(() => {
    if (bucketItems?.length) {
      return bucketItems[0].fulfillment_center_id;
    }
  }, [bucketItems]);
  const isContinueButtonDisabled = React.useMemo(() => bucketItemsDisplayedCount === 0, [bucketItemsDisplayedCount]);

  const resetCustomBoxFlow = React.useCallback(() => {
    dispatch(resetCustomSend());
  }, [dispatch]);

  const {
    Modal: UnsavedCustomBoxModal,
    openModal: openUnsavedCustomBoxModal,
    closeModal: closeUnsavedCustomBoxModal,
  } = useModal();

  const unsavedCustomBoxModalLayout = React.useMemo(
    () => (
      <UnsavedCustomBoxModal overlayClassName={styles.modalOverlay} className={cn(styles.modal, 'common-modal')}>
        {() => (
          <UnsavedChangesModal
            onClose={closeUnsavedCustomBoxModal}
            actions={[
              { title: 'Stay on the page' },
              { title: 'Discard changes', onClick: resetCustomBoxFlow, isOutlined: true },
            ]}
          />
        )}
      </UnsavedCustomBoxModal>
    ),
    [UnsavedCustomBoxModal, resetCustomBoxFlow, closeUnsavedCustomBoxModal],
  );

  const box = React.useMemo((): ICustomSend | undefined => {
    return bucketItems?.length
      ? {
          name: 'Added Items',
          description: '',
          box_id: 'custom',
          items: bucketItems || [],
        }
      : undefined;
  }, [bucketItems]);

  const campaignIsNotEligibleToOrder = React.useMemo(
    () => !isCampaignEligibleToOrder(bucketItems as ICampaignItem[]),
    [bucketItems],
  );

  const totalPrice = React.useMemo(() => {
    return getCampaignTotal({ items: box?.items });
  }, [box, getCampaignTotal]);

  const handleResetCustomBox = React.useCallback(() => {
    if (bucketItems?.length) {
      openUnsavedCustomBoxModal();
    } else {
      resetCustomBoxFlow();
    }
  }, [bucketItems, resetCustomBoxFlow, openUnsavedCustomBoxModal]);

  React.useEffect(() => {
    if (typeof setHeaderAction === 'function') {
      setHeaderAction(() => {
        handleResetCustomBox();
      });
    }
  }, [setHeaderAction, handleResetCustomBox]);

  const getIsItemDisabled = React.useCallback(
    (item: IInventoryItem) => {
      const isDisabledByFC = allowedFulfillmentCenter
        ? !(allowedFulfillmentCenter === item.fulfillment_center_id)
        : false;

      return isItemDigital(item) || isBrokenMSKU(item) || isItemOutOfStock(item) || isDisabledByFC;
    },
    [allowedFulfillmentCenter],
  );

  const getRequestInfo = React.useCallback(
    (payload?: Partial<IFetchInventoryItemsRequestPayload>) => {
      return {
        type: isDirectSend ? [InventoryFilterTypesEnum.Physical] : [],
        ...(preorderFilter && { include_preorder: 'on' }),
        page: payload?.page || 1,
        page_size: MAX_INVENTORY_ITEMS_PER_PAGE,
        ...(query && { search_query: query }),
        ...payload,
        ...(selectedFulfillmentCenter && { fulfillment_center_id: selectedFulfillmentCenter as string }),
      };
    },
    [query, selectedFulfillmentCenter, isDirectSend, preorderFilter],
  );

  const fetchInventory = React.useCallback(
    (payload?: Partial<IFetchInventoryItemsRequestPayload>, metadata?: IFetchInventoryItemsRequestMetadata) =>
      dispatch(fetchInventoryItemsRequest(getRequestInfo(payload), metadata ?? { shouldClearItems: true })),
    [dispatch, getRequestInfo],
  );

  const handlePaginationChange = React.useCallback(
    ({ page, setPage }: IPaginatedListChangeEvent) => {
      fetchInventory({ page }, { shouldClearItems: false });
      // In case there's an error on the endpoint, paginator will change the page number, which is not correct.
      // The fix is to wrap the request with the Promise and call the `setPage(page)` after the Promise is resolved
      setPage(page);
    },
    [fetchInventory],
  );

  const checkIsItemSelected = React.useCallback(
    (itemId: string) => {
      return bucketItems?.map((item) => item.item_id).includes(itemId);
    },
    [bucketItems],
  );

  const handleSelect = React.useCallback(
    (item: ICampaignItem) => {
      dispatch(addToBucket(item));
    },
    [dispatch],
  );

  const handleRemove = React.useCallback(
    ({ item_id }: { item_id: string }) => {
      dispatch(removeFromBucket(item_id));
    },
    [dispatch],
  );

  const handleOpenSidebar = React.useCallback(() => {
    if (!isContinueButtonDisabled) {
      dispatch(toggleUiElement({ boxDetailsSidebarIsShown: true }));
    }
  }, [isContinueButtonDisabled, dispatch]);

  const handleCloseSidebar = React.useCallback(() => {
    dispatch(toggleUiElement({ boxDetailsSidebarIsShown: false }));
  }, [dispatch]);

  const handleSubmit = React.useCallback(() => {
    if (next) {
      history.push(next);
    }
  }, [history, next]);

  const handleItemChange = React.useCallback(
    (payload: IChangeBucketItemPayload) => {
      dispatch(changeBucketItemValue(payload));
    },
    [dispatch],
  );

  const actionButtons = React.useMemo(
    () => [
      <ActionButton
        className={styles.continueButton}
        key="submit-button"
        onClick={handleSubmit}
        title="Continue"
        disabled={campaignIsNotEligibleToOrder}
      />,
    ],
    [handleSubmit, campaignIsNotEligibleToOrder],
  );

  React.useEffect(() => {
    dispatch(
      fetchInventoryItemsRequest({
        type: [],
        page_size: MAX_INVENTORY_ITEMS_PER_PAGE,
        page: 1,
      }),
    );
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(
      addBucketValue({
        [PRE_CREATED_ENGAGEMENT_ID]: CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID,
      }),
    );
  }, [dispatch]);

  React.useEffect(() => {
    if (!box?.items?.length) {
      dispatch(toggleUiElement({ boxDetailsSidebarIsShown: false }));
    }
  }, [box, dispatch]);

  React.useEffect(() => {
    fetchInventory();
  }, [fetchInventory]);

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.header}>
        <div className={styles.filters}>
          {SearchInput}
          <div className={styles.filtersLower}>
            {hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN, DEPT_ADMIN], adminType)
              ? FulfillmentCenterFilter({ isFiltered: true })
              : null}
            <CheckboxFilter
              isActive={preorderFilter}
              label="Preorder"
              onClick={() => setPreorderFilter((prev) => !prev)}
            />
          </div>
        </div>
        <BucketTotal total={totalPrice} onClick={handleOpenSidebar} count={bucketItemsDisplayedCount} />
      </div>
      <div className={styles.content}>
        <Loader className={styles.loader} isLoading={IsInventoryFetching} />
        <PaginatedList<IInventoryItem>
          totalCount={inventoryCount}
          ref={paginatedListRef}
          items={filteredItemsByFC || ([] as IInventoryItem[])}
          pageSize={MAX_INVENTORY_ITEMS_PER_PAGE}
          onChange={handlePaginationChange}
          isFinalItems={isFinalInventoryItem}
        >
          {({ items, pagination: Pagination }) =>
            items && items.length ? (
              <React.Fragment>
                <div className={styles.itemsList}>
                  {items?.map((item: IInventoryItem) => {
                    const {
                      item_id: itemId,
                      name,
                      image_url: icon,
                      price,
                      count,
                      type,
                      sku_options: skuOptions,
                      item_customization_allowed: isCustomizable,
                      fulfillment_center_id,
                      preorder_allowed: isPreorderAllowed,
                    } = item;
                    const isDisabled = getIsItemDisabled(item);
                    const isDigital = isItemDigital(item);

                    const hint = (() => {
                      if (isDisabled && isDigital) {
                        return 'Digital items are not available for adding to the custom send at the moment';
                      }
                      return;
                    })();

                    const handleItemClick = () =>
                      checkIsItemSelected(itemId)
                        ? handleRemove(item)
                        : handleSelect({ ...item, quantity: 1, is_hidden_for_recipient: false });

                    return (
                      <InventoryItem
                        fulfillment_center_id={fulfillment_center_id}
                        itemId={itemId}
                        title={name}
                        icon={icon}
                        price={price}
                        count={count}
                        type={type}
                        skuOptions={skuOptions}
                        onSelect={handleItemClick}
                        key={itemId}
                        isSelected={checkIsItemSelected(itemId)}
                        isDisabled={isDisabled}
                        hint={hint}
                        isCustomizable={isCustomizable}
                        className={styles.item}
                        iconClassName={styles.iconInner}
                        isPreorder={isPreorderAllowed}
                      />
                    );
                  })}
                </div>
                <div className={styles.buttonContainer}>
                  <FloatingButton
                    className={styles.pygBtnWrapper}
                    buttonClassName={styles.pygBtn}
                    trigger
                    disabled={isContinueButtonDisabled}
                    onClick={handleOpenSidebar}
                  >
                    <ContinueArrowIcon className={styles.btnIcon} />
                    <span className={styles.btnText}>Continue</span>
                  </FloatingButton>
                </div>
                <Pagination className={styles.pagination} />
              </React.Fragment>
            ) : (
              <NoResultsPlaceholder />
            )
          }
        </PaginatedList>
      </div>
      <BoxDetailsSidebar
        onSubmit={handleSubmit}
        onClose={handleCloseSidebar}
        onChangeItem={handleItemChange}
        onRemoveItem={handleRemove}
        onReset={handleResetCustomBox}
        isBoxSelected={false}
        box={boxDetailsSidebarIsShown ? box : undefined}
        isPYGEnabled={false}
        mode={BoxDetailsSidebarModeEnum.Edit}
        actionButtons={actionButtons}
        isDirectSend={isDirectSend}
      />
      {unsavedCustomBoxModalLayout}
    </div>
  );
};

export default CustomSendContainer;
