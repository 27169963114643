import { SetOptional } from 'type-fest';

import {
  AI_GIFT_SUGGESTION_ENABLED,
  CRM_SKIP_ALLOWED,
  CUSTOMIZE_ITEMS_PAGE_BODY,
  CUSTOMIZE_ITEMS_PAGE_TITLE,
  DEPARTMENT_BUDGET_ENABLED,
  DIGITAL_BALANCE_LIMIT_ENABLED,
  DIGITAL_BALANCE_LIMIT_VALUE,
  EMAIL_CUSTOM_SENDER,
  EXTERNAL_ID,
  MSKU_PAGE_BODY,
  MSKU_PAGE_TITLE,
  SIGN_IN_METHODS_SUPPORTS,
  SOB_ENABLED,
  WOOCOMMERCE_API_KEY,
  WOOCOMMERCE_HOST_URL,
  WOOCOMMERCE_SERVICE_ACCOUNT_USER_ID,
} from '../constants/organizations';
import { TCrmSupports, TEmailSupports } from './integrations';
import { IPromiseCallbacks } from './redux';
import { IApiError } from './shell';

//#region Related Enums

export enum OrganizationFilterTypesEnum {
  All,
  Activated,
  Deactivated,
}

export enum EmailLogoTypesEnum {
  Rectangular = 'rectangular',
  Square = 'square',
}

export enum OrganizationResetCustomizationOptionsTypesEnum {
  Email,
  Portal,
  ShippingPage,
}

export enum SigninMethodsEnum {
  Google = 'google.com',
  Outlook = 'microsoft.com',
  Email = 'password',
  All = 'all',
}

//#endregion

//#region State interface

export interface IOrganizationsState {
  current: {
    options: IOrganizationOptions | null;
    departmentList: IDepartment[] | null;
    departmentBudget: IDepartmentBudgetValue | null;
    isDepartmentSummariesLoading: boolean;
    isDepartmentUpdating: boolean;
    name: string | null;
    uid: string | null;
    error: IApiError | null;
  };
  items: IOrganizationItem[] | null;
  organizationDetails: TOrganizationsDetails | null;
  isOrganizationDetailsLoading: boolean;
  isLoading: boolean;
  isOrganizationsPIILoading: boolean;
  error: any;
}

//#endregion

//#region Main and related interfaces and types

export interface IOrganizationCustomizationOptions {
  email_use_org_logo: boolean;
  email_accent_color: string;
  email_main_color: string;
  email_logo_type: EmailLogoTypesEnum | '';
  portal_sidebar_color: string;
  portal_sidebar_text_color: string;
  portal_accent_color: string;
  portal_use_org_logo: boolean;
  portal_use_cobranding_logo: boolean;
  shipping_page_title: string;
  shipping_page_body: string;
  pick_your_gift_title: string;
  pick_your_gift_body: string;
  [MSKU_PAGE_TITLE]: string;
  [MSKU_PAGE_BODY]: string;
  [CUSTOMIZE_ITEMS_PAGE_TITLE]: string;
  [CUSTOMIZE_ITEMS_PAGE_BODY]: string;
  [EMAIL_CUSTOM_SENDER]?: string;
}

export interface IOrganizationOptions {
  crm_currently_supports: TCrmSupports;
  email_currently_supports: TEmailSupports;
  [SIGN_IN_METHODS_SUPPORTS]: SigninMethodsEnum;
  [CRM_SKIP_ALLOWED]: boolean;
  [SOB_ENABLED]: boolean;
  [DEPARTMENT_BUDGET_ENABLED]: boolean;
  [DIGITAL_BALANCE_LIMIT_ENABLED]: boolean;
  [DIGITAL_BALANCE_LIMIT_VALUE]?: number | null;
  [AI_GIFT_SUGGESTION_ENABLED]?: boolean;
}

export interface IDepartmentBudgetValue {
  available_budget: number;
  used_budget: number;
  budget_currency: string;
}

// TODO: recheck types in a future
//  `org_id` is not a part of response IDepartment model,
//  but it should be sent in the requests to ADD new department only
//  so we need to sort out the types here and make some refactoring inside the forms
export interface IDepartment {
  available_budget?: number;
  org_id?: string;
  used_budget?: number;
  uid: string;
  name: string;
  budget_currency?: string;
}

export interface IDepartmentDetails
  extends SetOptional<IDepartment, 'uid' | 'used_budget' | 'available_budget' | 'budget_currency'> {}

export interface IDelayedShippingPage {
  button_color: string;
  header_color: string;
  logo_url?: string;
}

export interface IShipAddress {
  name: string;
  email: string;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phone: string;
  residential?: boolean;
}

export interface IOrganizationItem {
  name: string;
  uid: string;
  square_logo_url: string;
  vector_image_url: string;
  order_key: string;
  delayed_shipping_base_url: string;
  shipping_address: IShipAddress;
  delayed_shipping_page: IDelayedShippingPage;
  salesforce_campaign_id: string;
  salesforce_client_id: string;
  salesforce_client_secret_has_value: boolean;
  rybbon_client_id: string;
  rybbon_client_secret: string;
  rybbon_client_secret_has_value: boolean;
  org_departments: IDepartment[];
  org_options: IOrganizationOptions;
  org_customization_options: IOrganizationCustomizationOptions;
  low_inventory_notification_emails: string[] | null;
  billing_plan_id?: string;
  currency_code?: string;
}

export interface IOrganizationRequestItem {
  square_logo_url: string;
  vector_image_url: string;
  rect_cobranding_logo_url: string;
  square_cobranding_logo_url: string;
  order_key: string;
  name: string;
  delayed_shipping_base_url: string;
  shipping_address: IShipAddress;
  uid: string;
  delayed_shipping_page: IDelayedShippingPage;
  salesforce_campaign_id: string;
  salesforce_client_id: string;
  salesforce_client_secret: string;
  [EXTERNAL_ID]: string;
  [WOOCOMMERCE_HOST_URL]: string;
  [WOOCOMMERCE_SERVICE_ACCOUNT_USER_ID]: string;
  [WOOCOMMERCE_API_KEY]: string;
  org_options: IOrganizationOptions;
  rybbon_client_id: string;
  rybbon_client_secret: string;
  org_customization_options: IOrganizationCustomizationOptions;
  low_inventory_notification_emails: string[] | null;
  billing_plan_id?: string;
}

// Used in OrganizationSidebar
export interface ISimplifiedOrganization
  extends Pick<IOrganizationItem, 'uid' | 'name' | 'org_departments' | 'org_options'> {
  logo_url?: string;
}

export type TOrganizationsDetails = Partial<IOrganizationRequestItem> & Partial<IOrganizationItem>;

export interface IOrganizationFormValues extends IOrganizationRequestItem {
  org_options: IOrganizationOptions;
  org_customization_options: IOrganizationCustomizationOptions;
}

export interface IOrderKeyProps {
  validating: boolean;
  error?: IValidateOrderKeyResponse | null;
  lastChecked: string | null;
}

//#endregion

//#region Request Interfaces and Types

export interface IFetchOrganizationRequestActionPayload {
  responseDataType?: OrganizationsRequestResponseDataTypeEnum;
}

export interface IFetchOrganizationSuccessActionPayload {
  organizations: IOrganizationItem[];
}

export enum OrganizationsRequestResponseDataTypeEnum {
  All = 'all',
}

export interface IAddOrganizationActionPayload extends IPromiseCallbacks {
  values: TOrganizationsDetails;
}
export interface IAddOrganizationActionResponse {
  id: string;
}

export interface IEditOrganizationActionPayload extends IPromiseCallbacks {
  values: TOrganizationsDetails;
  metadata?: { shouldReauthorize: boolean };
}

export interface IAddOrganizationDepartmentActionPayload extends IPromiseCallbacks {
  department: Omit<IDepartmentDetails, 'uid' | 'created_date' | 'used_budget'>;
}

export interface IEditOrganizationDepartmentActionPayload extends IPromiseCallbacks {
  department: IDepartment;
}

export interface IValidateOrderKeyActionPayload extends IPromiseCallbacks {
  orderKey: string;
}

export interface IValidateOrderKeyResponse {
  errors: string[] | null;
}

export interface IDepartmentBudgetSummariesResponse {
  department_budget_summaries: IDepartment[];
}

export interface IUpdateDepartmentBudgetRequest extends IPromiseCallbacks {
  department_id: string;
  available_budget: number;
}

export interface IRemovePIIRequestPayload extends IPromiseCallbacks {
  from: string;
  to: string;
  orgId: string;
}

//#endregion
