import cn from 'classnames';
import * as React from 'react';
import Select, {
  ClearIndicatorProps,
  GroupBase,
  MenuProps,
  MultiValueRemoveProps,
  OnChangeValue,
  OptionProps,
  Options,
  SingleValueProps,
} from 'react-select';

import { ReactComponent as DropdownIcon } from '../../../../assets/images/icon-dropdown-indicator.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/images/icon-search.svg';
import { ISelectorValue } from '../../../../types/shell';
import { RedCrossButton } from '../../buttons';
import { InputLabel } from '../../labels';

import './Selector.scss';

interface ISelectorProps<T> {
  value: OnChangeValue<T, boolean> | Options<T> | undefined;
  options: Options<T> | undefined;
  isMulti?: boolean;
  helperText?: string | React.ReactNode;
  placeholder?: string | boolean;
  containerClassName?: string;
  className?: string;
  clearClassName?: string;
  onChange?: (value: OnChangeValue<T, boolean>) => void;
  onBlur?: (...args: any[]) => void;
  onClear?: () => void;
  isSearchable?: boolean;
  menuIsOpen?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
  closeMenuOnSelect?: boolean;
  name?: string;
  isClearable?: boolean;
  clearHint?: string;
  concreteOption?: string;
  readOnly?: boolean;
  required?: boolean;
  error?: string | string[] | null;
  showErrors?: boolean;
  limit?: number;
  onLimitReach?: () => void;
  onMenuClose?: () => void;
  isLoading?: boolean;
  customMenu?: React.ComponentType<MenuProps<T>>;
  customOption?: React.ComponentType<OptionProps<T>>;
  customValue?: React.ComponentType<SingleValueProps<T>>;
  editMode?: boolean;
  tempValue?: OnChangeValue<T, boolean>;
  hint?: string;
}

const Selector = <T extends ISelectorValue>({
  options,
  placeholder,
  isMulti,
  helperText,
  value,
  onChange,
  containerClassName,
  isSearchable = true,
  menuIsOpen,
  isDisabled,
  name,
  closeMenuOnSelect = false,
  className,
  clearClassName,
  onBlur,
  isClearable = true,
  clearHint,
  concreteOption,
  readOnly = false,
  required = false,
  error,
  showErrors = true,
  onLimitReach,
  limit,
  onMenuClose,
  customMenu,
  customOption,
  customValue,
  autoFocus,
  isLoading,
  onClear,
  editMode,
  tempValue,
  hint,
}: ISelectorProps<T>) => {
  const DropdownIndicator = React.useCallback(
    (props: ClearIndicatorProps<T>) => {
      const { selectProps } = props;

      if (readOnly && !editMode) {
        return null;
      }
      if (isSearchable || (menuIsOpen && selectProps.menuIsOpen)) {
        return <SearchIcon />;
      }
      return <DropdownIcon className={cn('reactSelect__dropdown', { opened: selectProps.menuIsOpen })} />;
    },
    [editMode, isSearchable, menuIsOpen, readOnly],
  );

  const ClearIndicator = React.useCallback(
    (props: ClearIndicatorProps<T>) => {
      const {
        getStyles,
        clearValue,
        innerProps: { ...restInnerProps },
      } = props;

      const styles = getStyles('clearIndicator', props);
      return (
        <RedCrossButton
          className={cn('reactSelect__clear-indicator', clearClassName)}
          {...restInnerProps}
          style={styles as React.CSSProperties}
          size="xs"
          onClick={onClear || clearValue}
          hint={clearHint}
        />
      );
    },
    [clearClassName, onClear, clearHint],
  );

  const MultiValueRemove = React.useCallback(
    (props: MultiValueRemoveProps<T>) => {
      const {
        data,
        innerProps: { ...restInnerProps },
      } = props;

      if (concreteOption && data && data.value === concreteOption) {
        return null;
      }

      return <div {...restInnerProps}>x</div>;
    },
    [concreteOption],
  );

  const shouldShowValidationError = React.useMemo(
    () => showErrors && !readOnly && error,
    [showErrors, readOnly, error],
  );

  const handleChange = React.useCallback(
    (v: OnChangeValue<T, boolean>) => {
      if (!isMulti && limit) {
        console.warn('"limit" property has been set, when isMulti={false}');
      }
      if (Array.isArray(v) && limit && limit < v.length) {
        return onLimitReach?.();
      }
      onChange?.(v);
    },
    [isMulti, limit, onChange, onLimitReach],
  );

  const renderValidationError = React.useMemo(() => {
    if (!shouldShowValidationError) {
      return null;
    }
    return Array.isArray(error) ? (
      error.map((e) => (
        <span key={e} className="selector__container__error">
          {e}
        </span>
      ))
    ) : (
      <span className="selector__container__error">{error}</span>
    );
  }, [error, shouldShowValidationError]);

  return (
    <div className={cn('selector__container', containerClassName)}>
      <InputLabel value={helperText} required={required} hint={hint} />
      <Select<T, boolean, GroupBase<T>>
        autoFocus={autoFocus}
        onBlur={onBlur}
        name={name}
        onChange={handleChange}
        value={tempValue || value || null}
        placeholder={placeholder}
        openMenuOnClick={!readOnly || editMode}
        className={cn('reactSelectContainer', className, {
          menuOpened: menuIsOpen,
          searchable: isSearchable,
          readOnly: readOnly && !editMode,
        })}
        classNamePrefix="reactSelect"
        options={options}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={false}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        components={{
          ClearIndicator,
          DropdownIndicator,
          MultiValueRemove,
          ...(customMenu ? { Menu: customMenu } : void 0),
          ...(customOption ? { Option: customOption } : void 0),
          ...(customValue ? { SingleValue: customValue } : void 0),
        }}
        menuIsOpen={menuIsOpen}
        isClearable={isClearable}
        onKeyDown={(e) => {
          if (e.nativeEvent.code === 'Space' && !(e.target as HTMLInputElement).value) {
            e.preventDefault();
          }
        }}
        onMenuClose={onMenuClose}
      />
      {renderValidationError}
    </div>
  );
};

export default Selector;
