import cn from 'classnames';
import * as React from 'react';

import { NO_CURRENCY } from '../../../../constants/shell';
import { InputLabel } from '../../labels';
import { CurrencySelector } from '../index';
import { NumericInput } from '../Input';

import inputStyles from '../Input/Input.module.scss';
import styles from './MoneyInput.module.scss';

interface IProps<T> {
  currency: string | undefined;
  amount: number | undefined;
  className?: string;
  innerClassName?: string;
  onChange: (name: keyof T, value: string | number) => void;
  names: {
    currency: keyof T;
    amount: keyof T;
  };
  helperText?: string;
  readOnly?: boolean;
  isDisabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  errorClassName?: string;
  isReadOnly?: boolean;
  isRequired?: boolean;
  error?: string | string[] | undefined;
  placeholder?: string;
}

const MoneyInput = <T, _ = never>({
  currency,
  amount,
  className,
  innerClassName,
  onChange,
  names,
  helperText,
  isDisabled,
  onBlur,
  errorClassName,
  isReadOnly,
  isRequired,
  error,
  placeholder,
}: IProps<T>) => {
  const handleCurrencyChange = React.useCallback(
    (value: string) => {
      onChange(names.currency, value);
    },
    [names.currency, onChange],
  );

  const handleAmountChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      onChange(names.amount, value);
    },
    [names.amount, onChange],
  );

  return (
    <div className={cn(styles.container, inputStyles.inputContainer, className)}>
      {helperText && <InputLabel required={isRequired} value={helperText} />}
      <div className={cn(styles.inputInner, innerClassName)}>
        <CurrencySelector
          className={styles.currency}
          name={names.currency}
          value={currency}
          onChange={handleCurrencyChange}
          excludedOptions={NO_CURRENCY.value}
          displayFormat="short"
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
        />
        <NumericInput
          inline
          name={String(names.amount)}
          value={amount}
          onChange={handleAmountChange}
          onBlur={onBlur}
          error={error}
          innerClassName={styles.amount}
          decimalScale={0}
          errorClassName={errorClassName}
          readOnly={isReadOnly}
          required={isRequired}
          disabled={isDisabled}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default MoneyInput;
